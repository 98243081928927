import React, { memo } from "react";

type Props = {
  shop: {
    shopId: string;
    name: string;
  };
};

export const ShopLink = memo<Props>(({ shop: { name, shopId } }) => (
  <a
    href={`${import.meta.env.DASHBOARD_URL}/shop/${shopId}/edit`}
    target="_blank"
    rel="noopener noreferrer"
  >
    {name}
  </a>
));
