import React, { memo, useMemo } from "react";
import styled from "styled-components";
import { Layout, Menu, Radio } from "antd";
import { useAppContext } from "context/AppContext";

import Logo from "assets/logo_white.svg";
import { MenuItem } from "components/Layout/BaseLayout/Navigation/MenuItem";
import { gray } from "constants/colors";
import { environment, isProduction } from "constants/environments";
import { version } from "constants/package";
import { useSearchParams } from "hooks/useSearchParams";

const { Sider } = Layout;

const backgroundStyle = {
  background: gray[9],
};

const StyledSider = styled(Sider).attrs({ style: backgroundStyle })`
  overflow-y: auto;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 16px 0 28px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 32px;
`;

const StyledMenu = styled(Menu).attrs({ style: backgroundStyle })``;

const Flex = styled.div`
  flex: 1;
`;

const Spacer = styled.div`
  margin-bottom: 32px;
`;

const ThemeRadioGroupContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const VersionText = styled.div`
  text-align: center;
  font-size: 12px;
  color: ${gray[5]};

  span {
    display: inline-block;
  }
`;

const VersionLink = styled.a`
  text-decoration: none;
  color: white;
`;

type Props = {
  signOut: () => void;
};

export const Navigation = memo<Props>(({ signOut }) => {
  const { selectedMenuKey, theme, setTheme } = useAppContext();
  const { getSearchParam } = useSearchParams<"shopId">();
  const shopIdQuery = useMemo(
    () => (getSearchParam("shopId") ? `?shopId=${getSearchParam("shopId")}` : ""),
    [getSearchParam],
  );

  return (
    <StyledSider collapsible={false}>
      <Container>
        <LogoContainer>
          <Logo width={80} />
        </LogoContainer>
        <StyledMenu theme="dark" selectedKeys={[selectedMenuKey]} mode="inline">
          <MenuItem key="/" to={`/${shopIdQuery}`} text="印刷端末監視" />
          <MenuItem
            key="/emergency-print-backup"
            to={`/emergency-print-backup${shopIdQuery}`}
            text="緊急伝票振替状況"
          />
          <MenuItem key="/shop-status" to={`/shop-status${shopIdQuery}`} text="店舗状況" />
          <MenuItem
            key="/cash-register-status"
            to={`/cash-register-status${shopIdQuery}`}
            text="レジ状況"
          />
          <MenuItem key="/handy-status" to={`/handy-status${shopIdQuery}`} text="ハンディ状況" />
          <MenuItem key="/table-users" to={`/table-users${shopIdQuery}`} text="テーブル利用状況" />
          <MenuItem
            key="/table-user-grouping-events"
            to={`/table-user-grouping-events${shopIdQuery}`}
            text="グルーピング履歴"
          />
          <MenuItem
            key="/table-user-moved-events"
            to={`/table-user-moved-events${shopIdQuery}`}
            text="卓移動履歴"
          />
          <MenuItem key="/orders" to={`/orders${shopIdQuery}`} text="注文監視" />
          <MenuItem key="/print-targets" to={`/print-targets${shopIdQuery}`} text="印刷対象監視" />
          <MenuItem key="/shop-clients" to={`/shop-clients${shopIdQuery}`} text="Shop Clients" />
          <MenuItem key="/notifications" to={`/notifications${shopIdQuery}`} text="一斉通知" />
          <MenuItem key="/mutation-logs" to={`/mutation-logs${shopIdQuery}`} text="更新ログ" />
          <MenuItem key="/printer-setup" to={`/printer-setup${shopIdQuery}`} text="DP設定" />
          <MenuItem
            key="/offline-feature-usage"
            to={`/offline-feature-usage${shopIdQuery}`}
            text="オフライン利用状況"
          />
          <MenuItem
            key="/service-wide-config"
            to={`/service-wide-config${shopIdQuery}`}
            text="Service Wide Config"
          />
        </StyledMenu>
        <Flex />
        <StyledMenu theme="dark" mode="inline">
          <Menu.Item onClick={signOut}>ログアウト</Menu.Item>
        </StyledMenu>
        <Spacer />
        <ThemeRadioGroupContainer>
          <Radio.Group
            options={[
              { label: "Light", value: "light" },
              { label: "Dark", value: "dark" },
            ]}
            onChange={(e) => setTheme(e.target.value)}
            value={theme}
            optionType="button"
            buttonStyle="solid"
          />
        </ThemeRadioGroupContainer>
        <Spacer />
        <VersionText>
          <VersionLink href="/contributors">
            <span>{version}</span>
            {!isProduction && <span>({environment})</span>}
          </VersionLink>
        </VersionText>
      </Container>
    </StyledSider>
  );
});
