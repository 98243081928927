import * as Types from '../../types/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const ShopStatusesGetShops = gql`
    query ShopStatusesGetShops {
  shop(order_by: {name: asc}) {
    company {
      id
      name
    }
    companyId
    shopId
    changeDateTime
    enableAlert
    name
    shopRebootTime
    shopCloseConnectionTime
    tableUsers(limit: 1, order_by: {activatedAt: desc}) {
      id
      activatedAt
    }
    closeCashRegisters(limit: 1, order_by: {createdAt: desc}) {
      id
      createdAt
    }
  }
}
    `;
export const ShopStatusesUpdateShop = gql`
    mutation ShopStatusesUpdateShop($shopId: uuid!, $input: shop_set_input!) {
  update_shop_by_pk(_set: $input, pk_columns: {shopId: $shopId}) {
    shopId
  }
}
    `;
export type ShopStatusesGetShopsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ShopStatusesGetShopsQuery = (
  { __typename?: 'query_root' }
  & { shop: Array<(
    { __typename?: 'shop' }
    & Pick<Types.Shop, 'companyId' | 'shopId' | 'changeDateTime' | 'enableAlert' | 'name' | 'shopRebootTime' | 'shopCloseConnectionTime'>
    & { company: (
      { __typename?: 'company' }
      & Pick<Types.Company, 'id' | 'name'>
    ), tableUsers: Array<(
      { __typename?: 'tableUser' }
      & Pick<Types.TableUser, 'id' | 'activatedAt'>
    )>, closeCashRegisters: Array<(
      { __typename?: 'closeCashRegister' }
      & Pick<Types.CloseCashRegister, 'id' | 'createdAt'>
    )> }
  )> }
);

export type ShopStatusesUpdateShopMutationVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
  input: Types.ShopSetInput;
}>;


export type ShopStatusesUpdateShopMutation = (
  { __typename?: 'mutation_root' }
  & { update_shop_by_pk?: Types.Maybe<(
    { __typename?: 'shop' }
    & Pick<Types.Shop, 'shopId'>
  )> }
);


export const ShopStatusesGetShopsDocument = gql`
    query ShopStatusesGetShops {
  shop(order_by: {name: asc}) {
    company {
      id
      name
    }
    companyId
    shopId
    changeDateTime
    enableAlert
    name
    shopRebootTime
    shopCloseConnectionTime
    tableUsers(limit: 1, order_by: {activatedAt: desc}) {
      id
      activatedAt
    }
    closeCashRegisters(limit: 1, order_by: {createdAt: desc}) {
      id
      createdAt
    }
  }
}
    `;

/**
 * __useShopStatusesGetShopsQuery__
 *
 * To run a query within a React component, call `useShopStatusesGetShopsQuery` and pass it any options that fit your needs.
 * When your component renders, `useShopStatusesGetShopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShopStatusesGetShopsQuery({
 *   variables: {
 *   },
 * });
 */
export function useShopStatusesGetShopsQuery(baseOptions?: Apollo.QueryHookOptions<ShopStatusesGetShopsQuery, ShopStatusesGetShopsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShopStatusesGetShopsQuery, ShopStatusesGetShopsQueryVariables>(ShopStatusesGetShopsDocument, options);
      }
export function useShopStatusesGetShopsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShopStatusesGetShopsQuery, ShopStatusesGetShopsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShopStatusesGetShopsQuery, ShopStatusesGetShopsQueryVariables>(ShopStatusesGetShopsDocument, options);
        }
export type ShopStatusesGetShopsQueryHookResult = ReturnType<typeof useShopStatusesGetShopsQuery>;
export type ShopStatusesGetShopsLazyQueryHookResult = ReturnType<typeof useShopStatusesGetShopsLazyQuery>;
export type ShopStatusesGetShopsQueryResult = Apollo.QueryResult<ShopStatusesGetShopsQuery, ShopStatusesGetShopsQueryVariables>;
export const ShopStatusesUpdateShopDocument = gql`
    mutation ShopStatusesUpdateShop($shopId: uuid!, $input: shop_set_input!) {
  update_shop_by_pk(_set: $input, pk_columns: {shopId: $shopId}) {
    shopId
  }
}
    `;
export type ShopStatusesUpdateShopMutationFn = Apollo.MutationFunction<ShopStatusesUpdateShopMutation, ShopStatusesUpdateShopMutationVariables>;

/**
 * __useShopStatusesUpdateShopMutation__
 *
 * To run a mutation, you first call `useShopStatusesUpdateShopMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShopStatusesUpdateShopMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shopStatusesUpdateShopMutation, { data, loading, error }] = useShopStatusesUpdateShopMutation({
 *   variables: {
 *      shopId: // value for 'shopId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useShopStatusesUpdateShopMutation(baseOptions?: Apollo.MutationHookOptions<ShopStatusesUpdateShopMutation, ShopStatusesUpdateShopMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ShopStatusesUpdateShopMutation, ShopStatusesUpdateShopMutationVariables>(ShopStatusesUpdateShopDocument, options);
      }
export type ShopStatusesUpdateShopMutationHookResult = ReturnType<typeof useShopStatusesUpdateShopMutation>;
export type ShopStatusesUpdateShopMutationResult = Apollo.MutationResult<ShopStatusesUpdateShopMutation>;
export type ShopStatusesUpdateShopMutationOptions = Apollo.BaseMutationOptions<ShopStatusesUpdateShopMutation, ShopStatusesUpdateShopMutationVariables>;