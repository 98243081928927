import React from "react";
import styled from "styled-components";

import { MainLayout } from "components/Layout/MainLayout";
import { ShopSelector } from "components/ShopSelector";
import { Spacer } from "components/Spacer";
import { useEmergencyPrintBackup } from "hooks/useEmergencyPrintBackup";
import { useSearchParams } from "hooks/useSearchParams";
import { EmergencyPrintBackupTable } from "pages/EmergencyPrintBackup/EmergencyPrintBackupTable";

import { EmergencyPrintBackupButton } from "./EmergencyPrintBackupButton";

const TopRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  div {
    flex-grow: 1;
  }
`;

export const EmergencyPrintBackup = () => {
  const { getSearchParam } = useSearchParams<"shopId">();
  const shopId = getSearchParam("shopId");
  const { emergencyRoleIdReplacements, roles, loading, refetch } = useEmergencyPrintBackup({
    shopId,
  });
  return (
    <MainLayout title="緊急伝票振替状況" withoutEmergencyRoleIdReplacement>
      <Spacer size={12} />
      <TopRow>
        <ShopSelector />
        <EmergencyPrintBackupButton
          shopId={shopId}
          emergencyRoleIdReplacements={emergencyRoleIdReplacements}
          roles={roles}
          loading={loading}
          refetch={refetch}
          skipCurrentRoleDisplay
        />
      </TopRow>
      <Spacer size={12} />
      <EmergencyPrintBackupTable
        emergencyRoleIdReplacements={emergencyRoleIdReplacements}
        roles={roles}
        loading={loading}
        refetch={refetch}
      />
    </MainLayout>
  );
};
