import React, { memo, useMemo } from "react";
import styled from "styled-components";
import { Switch, Tooltip } from "antd";
import { TableProps } from "antd/lib/table";
import { QuestionCircleOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

import { AbbreviatedUuid } from "components/AbbreviatedUuid";
import { ShopLink } from "components/ShopLink";
import { Spacer } from "components/Spacer";
import { Table } from "components/Table";
import { usePagination } from "hooks/usePagination";
import { ShopSetInput } from "types/graphql";

import { ShopStatus } from "../types";

const HelpContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const HelpDescriptionText = styled.span`
  font-size: 11px;
`;

type Props = {
  loading?: boolean;
  shopStatuses: ShopStatus[];
  updateShop(shop: { shopId: string } & ShopSetInput): void;
};

export const ShopStatusTable = memo<Props>(({ loading, shopStatuses, updateShop }) => {
  const [pagination, setPagination] = usePagination();

  const columns = useMemo<TableProps<ShopStatus>["columns"]>(
    () => [
      {
        title: "業態 ID",
        width: 100,
        render(_, { companyId }) {
          return <AbbreviatedUuid uuid={companyId} />;
        },
      },
      {
        title: "店舗 ID",
        width: 100,
        render(_, { shopId }) {
          return <AbbreviatedUuid uuid={shopId} />;
        },
      },
      {
        title: "店舗名",
        dataIndex: "name",
        render(_: string, shopStatus) {
          return <ShopLink shop={shopStatus} />;
        },
      },
      {
        title: () => (
          <div>
            <div>
              営業状況
              <Spacer size={5} inline />
              <Tooltip
                title={
                  <HelpContainer>
                    <strong>【営業中】定義</strong>
                    <HelpDescriptionText>
                      【最新の卓立ち上げ時刻】&gt;【最終レジ精算時刻】
                    </HelpDescriptionText>
                    <HelpDescriptionText>
                      【最新の卓立ち上げ時刻】が【24時間以内】
                    </HelpDescriptionText>
                  </HelpContainer>
                }
              >
                <span>
                  <QuestionCircleOutlined />
                </span>
              </Tooltip>
            </div>
            （最終卓立ち上げ時刻）
          </div>
        ),
        width: 190,
        align: "center",
        render(_: string, { isDuringBusinessHours, lastTableUserActivatedTime }) {
          return isDuringBusinessHours ? (
            <div>
              営業中
              <br />({lastTableUserActivatedTime?.format("YYYY-MM-DD HH:mm:ss")})
            </div>
          ) : null;
        },
      },
      {
        title: "アラート",
        dataIndex: "enableAlert",
        width: 50,
        render(_: string, { enableAlert, shopId }) {
          return (
            <Switch
              checkedChildren="ON"
              unCheckedChildren="OFF"
              checked={enableAlert}
              onChange={(checked) => {
                updateShop({ shopId, enableAlert: checked });
              }}
            />
          );
        },
      },
      {
        title: "最終レジ精算時刻",
        width: 170,
        render(_: string, { lastCloseCashRegisterTime }) {
          return lastCloseCashRegisterTime
            ? lastCloseCashRegisterTime.format("YYYY/MM/DD HH:mm")
            : "なし";
        },
        defaultSortOrder: "descend" as const,
        sorter: (a, b) =>
          dayjs(a.closeCashRegisters[0]?.createdAt ?? 0).diff(
            dayjs(b.closeCashRegisters[0]?.createdAt ?? 0),
          ),
      },
    ],
    [updateShop],
  );

  return (
    <Table
      rowKey="id"
      columns={columns}
      dataSource={shopStatuses}
      loading={loading}
      bordered
      pagination={pagination}
      onChange={({ position: _, ...pagination }) => setPagination(pagination)}
    />
  );
});
