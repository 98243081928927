import React, { memo } from "react";

import { useEmergencyPrintBackup } from "hooks/useEmergencyPrintBackup";
import { useSearchParams } from "hooks/useSearchParams";
import { EmergencyPrintBackupButton } from "pages/EmergencyPrintBackup/EmergencyPrintBackupButton";

import { BaseLayout, BaseLayoutProps } from "../BaseLayout";

type Props = {
  withoutEmergencyRoleIdReplacement?: boolean;
} & Omit<BaseLayoutProps, "headerExtra">;

export const MainLayout = memo<Props>(
  ({ title, breadcrumb, onBack, withoutEmergencyRoleIdReplacement, children }) => {
    const { getSearchParam } = useSearchParams<"shopId">();
    const shopId = getSearchParam("shopId");
    const { emergencyRoleIdReplacements, roles, loading, refetch } = useEmergencyPrintBackup({
      shopId,
    });

    return (
      <BaseLayout
        title={title}
        breadcrumb={breadcrumb}
        onBack={onBack}
        headerExtra={
          withoutEmergencyRoleIdReplacement ? undefined : (
            <EmergencyPrintBackupButton
              shopId={shopId}
              emergencyRoleIdReplacements={emergencyRoleIdReplacements}
              roles={roles}
              loading={loading}
              refetch={refetch}
              skipCurrentRoleDisplay={false}
            />
          )
        }
      >
        {children}
      </BaseLayout>
    );
  },
);
