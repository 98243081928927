import { useEffect, useMemo, useState } from "react";
import { useAsyncFn } from "react-use";

import { EmergencyRoleIdReplacement } from "../../entity/emergencyRoleIdReplacement";
import { request } from "../../libs/api";

import { useMonitorGetAllRolesQuery } from "./queries";

export const useEmergencyPrintBackup = ({ shopId }: { shopId: string | undefined }) => {
  const [allEmergencyRoleIdReplacement, setAllEmergencyRoleIdReplacement] = useState(
    [] as EmergencyRoleIdReplacement[],
  );

  const { data: roleData } = useMonitorGetAllRolesQuery();
  const roles = useMemo(() => roleData?.role ?? [], [roleData?.role]);

  const [{ loading }, refetch] = useAsyncFn(async () => {
    const ret = await request.get(`emergency_role_replacement/all`);
    setAllEmergencyRoleIdReplacement(ret.data);
  }, []);

  useEffect(() => {
    void refetch();
  }, [refetch]);

  const currentShopEmergencyReplacements = useMemo(
    () =>
      shopId === undefined
        ? allEmergencyRoleIdReplacement
        : allEmergencyRoleIdReplacement.filter((val) => val.shopId === shopId),
    [allEmergencyRoleIdReplacement, shopId],
  );

  return {
    emergencyRoleIdReplacements: currentShopEmergencyReplacements,
    roles,
    loading,
    refetch,
  };
};
