import { useCallback, useEffect, useMemo, useState } from "react";

import { auth } from "libs/firebase";

const PREVIOUS_VERIFICATION = "dinii:previous_verification";
const VERIFICATION_EXPIRY = 6 * 60 * 60 * 1000;

type PageStatus = "uninitialized" | "verified" | "unverified";

type Verification = {
  verifiedAt: number;
  userEmail: string;
};

export const useVerification = () => {
  const userEmail = auth.currentUser?.email;

  const prevVerification = useMemo(() => {
    try {
      const raw = localStorage.getItem(PREVIOUS_VERIFICATION);
      return raw ? (JSON.parse(raw) as Verification) : null;
    } catch {
      return null;
    }
  }, []);

  const [status, setStatus] = useState<PageStatus>("uninitialized");

  useEffect(() => {
    if (!prevVerification) {
      setStatus("unverified");
      return;
    }

    if (!userEmail) return;

    const stillVerified =
      prevVerification.userEmail === userEmail &&
      Date.now() - VERIFICATION_EXPIRY < prevVerification.verifiedAt;

    setStatus(stillVerified ? "verified" : "unverified");
  }, [prevVerification, userEmail]);

  const verify = useCallback(
    (code: string) => {
      if (!userEmail) return;

      // NOTE: ソースコードを見ればコードがわかるが、そこまでのリテラシーがあるのであれば通ってヨシとする
      // develop は testshop2020
      if (import.meta.env.SERVICE_WIDE_CONFIG_VERIFICATION_CODE === code) {
        setStatus("verified");
        const verification: Verification = {
          userEmail,
          verifiedAt: Date.now(),
        };
        localStorage.setItem(PREVIOUS_VERIFICATION, JSON.stringify(verification));
      }
    },
    [userEmail],
  );

  const clearVerification = useCallback(() => {
    localStorage.removeItem(PREVIOUS_VERIFICATION);
    setStatus("unverified");
  }, []);

  return { status, verify, clearVerification };
};
