import React, { memo, useCallback, useMemo } from "react";
import styled from "styled-components";
import { Button, Col, Row, Tooltip } from "antd";
import { ClearOutlined } from "@ant-design/icons";
import { uniqBy } from "lodash";

import { Select } from "components/Input/Select";
import { UpdateFilterConditionFunctionType } from "hooks/useFilterConditions";

import { ShopStatus } from "../types";

export type FilterConditions = {
  companyId?: string;
  shopId?: string;
  isActive?: boolean;
  isAlertEnabled?: boolean;
};

const Wrapper = styled(Row).attrs({ gutter: 16 })`
  align-items: center;
  margin-bottom: 16px;
`;

type Props = {
  shopStatuses: ShopStatus[];
  hasFilterConditions: boolean;
  filterConditions: FilterConditions;
  updateFilterCondition: UpdateFilterConditionFunctionType<FilterConditions>;
  clearFilterConditions: () => void;
};

export const ShopStatusFilter = memo<Props>(
  ({
    shopStatuses,
    hasFilterConditions,
    filterConditions,
    updateFilterCondition,
    clearFilterConditions,
  }) => {
    const companies = useMemo(
      () =>
        uniqBy(
          shopStatuses.map(({ company }) => company),
          ({ id }) => id,
        ),
      [shopStatuses],
    );

    const handleFilterConditionChange = useCallback(
      (newText: string) => {
        updateFilterCondition({ shopId: newText });
      },
      [updateFilterCondition],
    );

    return (
      <Wrapper>
        <Col flex="1 0">
          <Select<string>
            placeholder="業態"
            optionFilterProp="label"
            options={companies.map(({ id, name }) => ({ label: name, value: id }))}
            value={companies.length ? filterConditions.companyId : null}
            onChange={(companyId) => updateFilterCondition({ companyId })}
            showSearch
            allowClear
          />
        </Col>
        <Col flex="1 0">
          <Select<string>
            placeholder="店舗"
            optionFilterProp="label"
            options={shopStatuses.map(({ shopId, name }) => ({ label: name, value: shopId }))}
            value={shopStatuses.length ? filterConditions.shopId : null}
            onChange={handleFilterConditionChange}
            showSearch
            allowClear
          />
        </Col>
        <Col flex="1 0">
          <Select<string>
            placeholder="営業状況"
            optionFilterProp="label"
            options={[
              { label: "営業中", value: "active" },
              { label: "営業時間外", value: "inactive" },
            ]}
            value={
              filterConditions.isActive
                ? "active"
                : filterConditions.isActive === undefined
                ? undefined
                : "inactive"
            }
            onChange={(status) =>
              updateFilterCondition({
                isActive: status === "active" ? true : status === "inactive" ? false : undefined,
              })
            }
            showSearch
            allowClear
          />
        </Col>
        <Col flex="1 0">
          <Select<string>
            placeholder="アラート設定"
            optionFilterProp="label"
            options={[
              { label: "有効", value: "active" },
              { label: "無効", value: "inactive" },
            ]}
            value={
              filterConditions.isAlertEnabled
                ? "active"
                : filterConditions.isAlertEnabled === undefined
                ? undefined
                : "inactive"
            }
            onChange={(status) =>
              updateFilterCondition({
                isAlertEnabled:
                  status === "active" ? true : status === "inactive" ? false : undefined,
              })
            }
            showSearch
            allowClear
          />
        </Col>
        <Col flex="none">
          <Tooltip title="フィルター条件をクリア">
            <Button
              shape="circle"
              icon={<ClearOutlined />}
              disabled={hasFilterConditions}
              onClick={clearFilterConditions}
            />
          </Tooltip>
        </Col>
      </Wrapper>
    );
  },
);
