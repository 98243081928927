import React, { memo, useCallback, useState } from "react";
import styled from "styled-components";
import { message } from "antd";
import { useAppContext } from "context/AppContext";
import { copyTextToClipboard } from "util/copyTextToClipboard";

const IdContainer = styled.div`
  cursor: copy;
  white-space: nowrap;
  text-overflow: hidden;
  position: relative;
`;

const FullId = styled.div<{ isDarkThemeEnabled: boolean }>`
  cursor: copy;
  position: absolute;
  left: calc(-0.25em - 4.5px);
  top: 50%;
  background-color: ${({ isDarkThemeEnabled }) => (isDarkThemeEnabled ? `#232323` : "white")};
  z-index: 1;
  padding: 0.25em 0.5em;
  border-radius: 4px;
  border: 1px solid ${({ isDarkThemeEnabled }) => (isDarkThemeEnabled ? `white` : "black")};
  transform: translateY(-50%);
`;

type Props = {
  uuid: string;
};

export const AbbreviatedUuid = memo<Props>(({ uuid }) => {
  const { theme: selectedTheme } = useAppContext();
  const isDarkThemeEnabled = selectedTheme === "dark";
  const [isHovering, setIsHovering] = useState(false);

  const copyToClipboard = useCallback(() => {
    message.success("IDをコピーしました");
    copyTextToClipboard(uuid);
  }, [uuid]);

  const uuidFirstSegment = uuid.split("-")[0] ?? "";

  return (
    <IdContainer
      onMouseOver={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      onClick={copyToClipboard}
      title="IDをコピー"
    >
      <div>{uuidFirstSegment} ...</div>
      {isHovering ? <FullId isDarkThemeEnabled={isDarkThemeEnabled}>{uuid}</FullId> : null}
    </IdContainer>
  );
});
