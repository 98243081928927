import { useAsyncFn } from "react-use";
import { Form } from "antd";
import { ValidateErrorEntity } from "rc-field-form/es/interface";
import { isValidateErrorEntity } from "util/validation";

export type ShopClientFormFormValues = {
  name?: string;
  email?: string;
  password?: string;
};

const getInitialValues = () => ({});

export const useCreateShopClientDialogForm = ({
  onSubmit,
  onFormValidationError,
}: {
  onSubmit: (formValues: ShopClientFormFormValues) => Promise<void>;
  onFormValidationError: (args: { formValidationError: ValidateErrorEntity }) => void;
}) => {
  const [form] = Form.useForm();
  const initialValues = getInitialValues();

  const [{ loading }, submit] = useAsyncFn(async () => {
    try {
      const values = (await form.validateFields()) as ShopClientFormFormValues;
      await onSubmit({ ...values });
    } catch (e) {
      if (isValidateErrorEntity(e)) onFormValidationError({ formValidationError: e });
    }
  }, [form, onFormValidationError, onSubmit]);

  return { form, initialValues, submit, submitting: loading };
};
