/**
 * Copy text to user clipboard.
 */
export const copyTextToClipboard = async (text: string) => {
  try {
    await navigator.clipboard.writeText(text);
  } catch (error) {
    // NOTE: Android対応
    // https://www.line-community.me/ja/question/61bc50b869e9a56508e56c4e
    const input = document.createElement("textarea");
    document.body.appendChild(input);
    input.value = text;
    input.select();
    document.execCommand("copy");
    document.body.removeChild(input);
  }
};
