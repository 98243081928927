import React, { memo } from "react";
import { Modal } from "antd";
import { EmergencyRoleIdReplacement } from "entity/emergencyRoleIdReplacement";
import { Role } from "entity/role.entity";

import { ShopSelector } from "components/ShopSelector";
import { Spacer } from "components/Spacer";
import { EmergencyPrintBackupTable } from "pages/EmergencyPrintBackup/EmergencyPrintBackupTable";

type Props = {
  roles: Role[];
  emergencyRoleIdReplacements: EmergencyRoleIdReplacement[];
  refetch: () => void;
  onAddNewSetting: () => void;
  onCancel: () => void;
};

export const CurrentRoleTableModal = memo<Props>(
  ({ roles, emergencyRoleIdReplacements, refetch, onAddNewSetting, onCancel }) => (
    <Modal
      title="振替設定"
      open
      width={800}
      onOk={onAddNewSetting}
      okText="追加"
      onCancel={onCancel}
    >
      <ShopSelector />

      <Spacer size={16} />

      <EmergencyPrintBackupTable
        emergencyRoleIdReplacements={emergencyRoleIdReplacements}
        roles={roles}
        loading={false}
        refetch={refetch}
      />
    </Modal>
  ),
);
