import React, { memo, useState } from "react";
import styled from "styled-components";
import { Checkbox, List, Modal, Typography } from "antd";
import { Role } from "entity/role.entity";

import { ShopSelector } from "components/ShopSelector";
import { Spacer } from "components/Spacer";

const listStyle = { flex: 1 };

const ListContainer = styled.div`
  display: flex;
  gap: 32px;
`;

type Props = {
  shopId?: string;
  roles: Role[];
  onAddEmergencyPrintBackup: ({
    fromRoleId,
    toRoleId,
  }: {
    fromRoleId: number;
    toRoleId: number;
  }) => void;
  onCancel: () => void;
};

export const NewReplacementSettingModal = memo<Props>(
  ({ shopId, roles, onAddEmergencyPrintBackup, onCancel }) => {
    const [fromRoleId, setFromRoleId] = useState<number | null>(null);
    const [toRoleId, setToRoleId] = useState<number | null>(null);
    return (
      <Modal
        title="振替設定"
        open
        width={800}
        onOk={
          fromRoleId && toRoleId
            ? () => onAddEmergencyPrintBackup({ fromRoleId, toRoleId })
            : undefined
        }
        okText="適用"
        onCancel={onCancel}
      >
        <ShopSelector />

        <Spacer size={16} />

        <ListContainer>
          <List
            // eslint-disable-next-line react/forbid-component-props
            style={listStyle}
            header="From"
            bordered
            dataSource={roles.filter((r) => r.shopId === shopId)}
            renderItem={(role) => (
              <List.Item>
                <Typography>
                  {role.name}({role.roleId})
                </Typography>

                <Checkbox
                  disabled={toRoleId === role.roleId}
                  onChange={() => setFromRoleId(role.roleId)}
                  checked={fromRoleId === role.roleId}
                />
              </List.Item>
            )}
          />

          <List
            // eslint-disable-next-line react/forbid-component-props
            style={listStyle}
            header="To"
            bordered
            dataSource={roles.filter((r) => r.shopId === shopId)}
            renderItem={(role) => (
              <List.Item>
                <Typography>
                  {role.name}({role.roleId})
                </Typography>

                <Checkbox
                  disabled={fromRoleId === role.roleId}
                  onChange={() => setToRoleId(role.roleId)}
                  checked={toRoleId === role.roleId}
                />
              </List.Item>
            )}
          />
        </ListContainer>
      </Modal>
    );
  },
);
