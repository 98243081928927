import React, { memo, useCallback, useState } from "react";
import styled from "styled-components";
import { Button, message } from "antd";
import { WarningOutlined } from "@ant-design/icons";
import { EmergencyRoleIdReplacement } from "entity/emergencyRoleIdReplacement";

import { Spacer } from "components/Spacer";

import { Role } from "../../../entity/role.entity";
import { request } from "../../../libs/api";

import { CurrentRoleTableModal } from "./CurrentRoleTableModal";
import { NewReplacementSettingModal } from "./NewReplacementSettingModal";

const startReplacementRequest = async ({
  shopId,
  roleId,
  replaceToRoleId,
}: {
  shopId: string;
  roleId: number;
  replaceToRoleId: number;
}): Promise<string> => {
  try {
    await request.post(`admin/${shopId}/roleReplacement/${roleId}`, { replaceTo: replaceToRoleId });
    return "ok";
  } catch (e) {
    return `error: ${e}`;
  }
};

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

type Props = {
  roles: Role[];
  emergencyRoleIdReplacements: EmergencyRoleIdReplacement[];
  loading: boolean;
  shopId?: string | undefined;
  skipCurrentRoleDisplay: boolean;
  refetch: () => void;
};

export const EmergencyPrintBackupButton = memo<Props>(
  ({ roles, emergencyRoleIdReplacements, skipCurrentRoleDisplay, refetch, loading, shopId }) => {
    const [openCurrentRoleTableDialog, setOpenCurrentRoleTableDialog] = useState(false);
    const [openReplacementDialog, setOpenReplacementDialog] = useState(false);

    const showNewSettingModal = useCallback(() => {
      setOpenCurrentRoleTableDialog(false);
      setOpenReplacementDialog(true);
    }, []);

    const onClick = useCallback(() => {
      if (skipCurrentRoleDisplay) {
        setOpenReplacementDialog(true);
      } else {
        setOpenCurrentRoleTableDialog(true);
      }
    }, [skipCurrentRoleDisplay]);

    const onCloseModals = useCallback(() => {
      setOpenCurrentRoleTableDialog(false);
      setOpenReplacementDialog(false);
    }, []);

    const onAddEmergencyPrintBackup = ({
      fromRoleId,
      toRoleId,
    }: {
      fromRoleId: number;
      toRoleId: number;
    }) => {
      if (!shopId) return;

      const result = window.confirm("緊急伝票振り分け設定を適用します。よろしいですか？");
      if (!result) {
        return;
      }

      startReplacementRequest({
        shopId,
        roleId: fromRoleId,
        replaceToRoleId: toRoleId,
      }).then((ret) => {
        message.success(ret);
        refetch();
        setOpenReplacementDialog(false);
      });
    };

    return (
      <div>
        <ButtonWrapper>
          <Button type="primary" onClick={onClick} icon={<WarningOutlined />} danger>
            緊急伝票振替{" "}
            {loading ? <Spacer size={10} inline /> : emergencyRoleIdReplacements.length}
          </Button>
        </ButtonWrapper>
        <Spacer size={12} />

        {openCurrentRoleTableDialog ? (
          <CurrentRoleTableModal
            roles={roles}
            emergencyRoleIdReplacements={emergencyRoleIdReplacements}
            refetch={refetch}
            onAddNewSetting={showNewSettingModal}
            onCancel={onCloseModals}
          />
        ) : null}
        {openReplacementDialog ? (
          <NewReplacementSettingModal
            shopId={shopId}
            roles={roles}
            onAddEmergencyPrintBackup={onAddEmergencyPrintBackup}
            onCancel={onCloseModals}
          />
        ) : null}
      </div>
    );
  },
);
